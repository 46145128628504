import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useState, useEffect, setState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import config from "config";
import axios from "axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#649C0D",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PulsSection = () => {
  const [records, setRecords] = useState();
  const [activRrecords, setActiveRecords] = useState("");
  const [progress, SetProgress] = useState(true);

  useEffect(() => {
    axios.post(`${config.url}/emeter/pulsesession`).then((res) => {
      setRecords(res.data.xyz);
      SetProgress(false);

      setActiveRecords(res.data.latestrecord[0]);
    });
  }, []);

  function handleTime(date) {
    const d = new Date(date);
    let hour = d.getHours();
    let min = d.getMinutes();
    let sec = d.getSeconds();
    const amOrpm = hour >= 12 ? "Pm" : "Am";
    if (hour > 12) {
      hour -= 12;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }

    if (min < 10) {
      min = "0" + min;
    }

    if (sec < 10) {
      sec = "0" + sec;
    }

    return `${hour}:${min}:${sec} ${amOrpm}`;
  }

  return (
    <>
      {progress ? (
        <div
          style={{
            height: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress disableShrink sx={{ animationDuration: "550ms" }} />
        </div>
      ) : (
        <div>
          {" "}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Serial No</StyledTableCell>
                  {/* <StyledTableCell align="center">Kwh From</StyledTableCell> */}
                  {/* <StyledTableCell align="center">Kwh To</StyledTableCell> */}
                  <StyledTableCell align="center">Puls(Kw)</StyledTableCell>
                  <StyledTableCell align="center">Time</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activRrecords && (
                  <StyledTableRow key={activRrecords._id}>
                    <StyledTableCell
                      component="th"
                      scope="activRrecords"
                      align="center"
                    >
                      {activRrecords.imi_number}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                    <input
                        key={activRrecords._id}
                        type="checkbox"
                        checked={checked.includes(activRrecords._id)}
                        onChange={(e) => handleCheckboxChange(e, activRrecords)}
                    />
                </StyledTableCell> */}
                    {/* <StyledTableCell align="center">{activRrecords.pulsefrom}</StyledTableCell> */}
                    {/* <StyledTableCell align="center">{activRrecords.pulseto}</StyledTableCell> */}
                    <StyledTableCell align="center">
                      {activRrecords.pulse}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {handleTime(activRrecords.createdAt)}
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">S.No.</StyledTableCell>
                  {/* <StyledTableCell align="center">Kwh From</StyledTableCell> */}
                  {/* <StyledTableCell align="center">Kwh To</StyledTableCell> */}
                  <StyledTableCell align="center">Consumption</StyledTableCell>
                  <StyledTableCell align="center">
                    Time Used(HH:MM:SS)
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records &&
                  records.map((row, key) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {key + 1}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                    <input
                        key={row._id}
                        type="checkbox"
                        checked={checked.includes(row._id)}
                        onChange={(e) => handleCheckboxChange(e, row)}
                    />
                </StyledTableCell> */}
                      {/* <StyledTableCell align="center">{row.pulsefrom}</StyledTableCell> */}
                      {/* <StyledTableCell align="center">{row.pulseto}</StyledTableCell> */}
                      <StyledTableCell align="center">
                        {row.pulsedifference}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.time_difference}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};

export default PulsSection;
