// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

//dashboard for admin role
import config from 'config';
//basename={config.basename}

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Home',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: `${config.basename}/dashboard/default`,
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        // {
        //     id: 'meter-puls',
        //     title: 'Meter Puls',
        //     type: 'item',
        //     url: `${config.basename}/meter/puls`,
        //     // icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'meter-section',
        //     title: 'Puls Section',
        //     type: 'item',
        //     url: `${config.basename}/puls/puls-section`,
        //     // icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
    ]
};

export default dashboard;
