import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

//show layout on basis of role of admin
import config from 'config';
import { element } from 'prop-types';
//basename={config.basename}

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
//const AdminLoginDashboard = Loadable(lazy(() => import('views/dashboard/Default/LoginAdmin')));

const MeterPuls = Loadable(lazy(() => import('views/MeterPuls/MeterPuls')));


// utilities routing
const UtilUser = Loadable(lazy(() => import('views/utilities/ViewUsers')));
const PdfGen = Loadable(lazy(() => import('views/utilities/PdfGenerator')));
const UtilsDealer = Loadable(lazy(() => import('views/utilities/ViewDealer')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const UtilsAddUser = Loadable(lazy(() => import('views/utilities/AddUser')));
const UtilsAddDealer = Loadable(lazy(() => import('views/utilities/AddDealer')));
const UtilsUpdateDealer = Loadable(lazy(() => import('views/utilities/UpdateDealer')));
const UtilsUpdateUser = Loadable(lazy(() => import('views/utilities/UpdateUser')));
const UtilsViewEMeter = Loadable(lazy(() => import('views/utilities/ViewEMeter')));
const UtilsAddElectricMeter = Loadable(lazy(() => import('views/utilities/AddElectricMeter')));
const UtilsMeter = Loadable(lazy(() => import('views/utilities/ViewAllTransaction')));
const UtilsAddMeterTransaction = Loadable(lazy(() => import('views/utilities/AddMeterTransaction')));
const UtilsUpdateEmeter = Loadable(lazy(() => import('views/utilities/UpdateEmeter')));
const UtilsAddDistributor = Loadable(lazy(() => import('views/utilities/AddDistributor')));
const UtilsViewDistributor = Loadable(lazy(() => import('views/utilities/ViewDistributors')));
const UtilsUpdateDistributor = Loadable(lazy(() => import('views/utilities/UpdateDistributor')));
const AddBulkEmeters = Loadable(lazy(() => import('views/utilities/AddBulkEmeter')));
const EditForm = Loadable(lazy(() => import('../layout/MainLayout/Header/ProfileSection/EditForm')));
const UtilsassigneMeter = Loadable(lazy(() => import('views/utilities/AssigneMeter')));
const UtilsViewAssignedMeter = Loadable(lazy(() => import('views/utilities/ViewAssignedMeter')));
const UtilsViewAssignedMeterDetails =Loadable(lazy(() => import('views/utilities/ViewAssinedMeterDetails')));
const UserDetails =Loadable(lazy(() => import('views/utilities/UserDetails')));
// const PulsSection =Loadable(lazy(() => import('views/MeterPuls/PulsSection')));
const UtilsViewMore = Loadable(lazy(() => import('views/utilities/ViewDealerdata')));
const UtilsViewDealerdtrb = Loadable(lazy(() => import('views/utilities/ViewDealerdtrb')));
const PageNotFound= Loadable(lazy(() => import('views/utilities/PageNotFound')));
const UtilsPayment= Loadable(lazy(() => import('views/utilities/ViewPaymentHistory')));
const UtilsViewSettingdata= Loadable(lazy(() => import('views/utilities/ViewSettingdata')));
const UtilsEditSettingdata= Loadable(lazy(() => import('views/utilities/UpdateSettingdata')));
const UtilsViewUnitcunsuptionhistry= Loadable(lazy(() => import('views/utilities/Unitcunsuptionhistory')));
const UtilsViewUnitmtr= Loadable(lazy(() => import('views/utilities/ViewMeterWithdrawal')));
const UtilsStaticPage= Loadable(lazy(() => import('views/utilities/StaticsPage')));
const UtilsStaticPageUpdate= Loadable(lazy(() => import('views/utilities/EditStaticdata')));
const UtilsaddStatsdata= Loadable(lazy(() => import('views/utilities/EditorToolbar')));
const UtilsaddQA= Loadable(lazy(() => import('views/utilities/AddQA')));
const UtilsviewQuaetion= Loadable(lazy(() => import('views/utilities/ViewAllQaList')));
// const UtilsViewDealerdtrb =Loadable(lazy(() => import('views/MeterPuls/PulsSection'))); 


const MainRoutes = {
    path: `${config.basename}`,
    element: <MainLayout />,
    children: [
       
        {
            path: 'dashboard',
            // element: <Error />,
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },

        {
            path:'meter',
            children:[
                {
                    path:'puls',
                    element:<MeterPuls/>
                }
            ]
        },
        // {
        //     path:'puls',
        //     children:[
        //         {
        //             path:'puls-section',
        //             element:<PulsSection/>  
        //         }
        //     ]
        // },
        {
            path: 'user',
            children: [
                {
                    path: 'edit-profile',
                    element: <EditForm />
                }
            ]
        },

        {
            path: 'utils',
            children: [
                {
                    path: 'util-viewalltransactions',
                    element: <UtilsMeter />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-addmetertransaction',
                    element: <UtilsAddMeterTransaction />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-viewemeter',
                    element: <UtilsViewEMeter />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-addelectricmeter',
                    element: <UtilsAddElectricMeter />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-addemeter-bulk',
                    element: <AddBulkEmeters />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-updateemeter',
                    element: <UtilsUpdateEmeter />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-qr',
                    element: <PdfGen/>
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-user',
                    element: <UtilUser />
                },
                {
                    path:'user-details',
                    element: <UserDetails/>
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-add',
                    element: <UtilsAddUser />
                }
            ]
        },

        {
            path: 'utils',
            children: [
                {
                    path: 'util-updateuser',
                    element: <UtilsUpdateUser />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-dlr',
                    element: <UtilsDealer />
                }
            ]
        },

        {
            path: 'utils',
            children: [
                {
                    path: 'util-adddlr',
                    element: <UtilsAddDealer />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-updatedlr',
                    element: <UtilsUpdateDealer />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-pyt',
                    element: <UtilsPayment />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-adddstr',
                    element: <UtilsAddDistributor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-viewdstr',
                    element: <UtilsViewDistributor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-updatedstr',
                    element: <UtilsUpdateDistributor />
                }
            ]
        },

        {
            path: 'utils',
            children: [
                {
                    path: 'util-assignmeter',
                    element: <UtilsassigneMeter />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-ViewAssignedMeter',
                    element: <UtilsViewAssignedMeter />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-ViewAssignedMeterDetails',
                    element: <UtilsViewAssignedMeterDetails />
                }]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-ViewDealerdtrb',
                    element: <UtilsViewDealerdtrb />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-ViewMore',
                    element: <UtilsViewMore />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-globalsetting',
                    element: <UtilsViewSettingdata />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-editglobalsetting',
                    element: <UtilsEditSettingdata />
                }
            ]
        },
        {
            path: '*',
            element: <PageNotFound />
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-ViewUnitcunsuptionhistry',
                    element: <UtilsViewUnitcunsuptionhistry />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-mtr',
                    element: <UtilsViewUnitmtr />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-static',
                    element: <UtilsStaticPage />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-staticUpdate',
                    element: <UtilsStaticPageUpdate />
                }
            ]
        },
        {
            path: 'utils',  
            children: [
                {
                    path: 'util-addStatsdata',
                    element: <UtilsaddStatsdata />
                }
            ]
        },
        {
            path: 'utils',  
            children: [
                {
                    path: 'util-addqa',
                    element: <UtilsaddQA />
                }
            ]
        },
        {
            path: 'utils',  
            children: [
                {
                    path: 'util-viewQuaetion',
                    element: <UtilsviewQuaetion />
                }
            ]
        },

    ]
};

export default MainRoutes;
