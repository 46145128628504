import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import App from 'App';
import { store } from 'store';
import PulsSection from 'views/MeterPuls/PulsSection';
import RouteS from 'routes';
//<Route path="/admin" element={<App />} />

// style + assets
import 'assets/scss/style.scss';
import config from './config';
import ProductContextProvider from 'context/DataContext';
import LoginAdmin from 'LoginAdmin';
import PrivateRoute from 'PrivateRoute';
import Login from 'Login';
import OTPVerify from 'OTPVerify';
import OtherPrivateRoute from 'OtherPrivateRoute';
import Error from 'views/utilities/PageNotFound';
//basename={config.basename}

const container = document.getElementById('root');
//console.log(`${config.basename}` || `${config.guestname}`);
//console.log(` current name = ${config.basename}`);
//console.log(`${config.basename}` === `${/admin}` ? 'true' : 'false'),
const root = createRoot(container);
root.render(
    <ProductContextProvider>
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LoginAdmin />} />
                    {/* <Route path="/meter-puls-section" element={<PulsSection/>} /> */}
                    {/* <Route path="/login" element={<Login />} />
                    <Route path="/otpverify" element={<OTPVerify />} /> */}
                    {/* <Route
                        element={
                            path="/dealer/*"
                            <OtherPrivateRoute>
                            <App />
                            </OtherPrivateRoute>
                        }
                    /> */}
                    <Route
                        path="/admin/*"
                        element={
                            <PrivateRoute>
                                <App />
                            </PrivateRoute>
                        }
                    />
                    {/* <Route path="*" element={<Error/>} /> */}
                </Routes>
                <App />
            </BrowserRouter>
        </Provider>
    </ProductContextProvider>
);
