import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Outlet, Navigate } from 'react-router-dom';
import { islogin } from 'auth/index';

function PrivateRoute({ children }) {
    const navigate = useNavigate();
    const jwttoken = localStorage.getItem('jwt');
    //localStorage.setItem('loginstatus', '1');
    // if (!jwttoken) {
    //     return <Navigate to="/" replace />;
    // }
    // return children;
    if (jwttoken) {
        //console.log('hello');
        return children;
    } else {
        //console.log('not login');
        return <Navigate to="/" replace />;
    }

    //return islogin() ? <Outlet /> : navigate('/login');
    // const navigate = useNavigate();
    // useEffect(() => {
    //     if (!localStorage.getItem('jwt')) {
    //         navigate('/login');
    //     }
    // }, []);

    // const [jwt, setJwt] = useState(localStorage.setItem('jwt', ''));
    //jwt ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
