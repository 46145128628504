// assets
import ElectricMeterOutlinedIcon from '@mui/icons-material/ElectricMeterOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CurrencyRupeeSharpIcon from '@mui/icons-material/CurrencyRupeeSharp';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ContrastOutlinedIcon from '@mui/icons-material/ContrastOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import SettingsIcon from '@mui/icons-material/Settings';

//import according to role
import config from 'config';
//basename={config.basename}

const utilities = {
    id: 'utilities',
    // title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-meter',
            title: 'Meter',
            type: 'collapse',
            icon: ElectricMeterOutlinedIcon,
            children: [
                {
                    id: 'util-meter',
                    title: 'View All Transactions',
                    type: 'item',
                    url: `${config.basename}/utils/util-viewalltransactions`,
                    breadcrumbs: false
                },
                {
                    id: 'util-addmeter',
                    title: 'Add Meter Transaction',
                    type: 'item',
                    url: `${config.basename}/utils/util-addmetertransaction`,
                    breadcrumbs: false
                },
                {
                    id: 'util-viewmeter',
                    title: 'View Electric-Meters',
                    type: 'item',
                    url: `${config.basename}/utils/util-viewemeter`,
                    breadcrumbs: false
                },
                {
                    id: 'util-addelectricmeter',
                    title: 'Add Electic-Meter',
                    type: 'item',
                    url: `${config.basename}/utils/util-addelectricmeter`,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'util-user',
            title: 'Users',
            type: 'collapse',
            icon: GroupOutlinedIcon,
            children: [
                {
                    id: 'util-user',
                    title: 'View Users',
                    type: 'item',
                    url: `${config.basename}/utils/util-user`,
                    breadcrumbs: false
                },
                // {
                //     id: 'util-add',
                //     title: 'Add User',
                //     type: 'item',
                //     url: `${config.basename}/utils/util-add`,
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'util-dlr',
            title: 'Dealers',
            type: 'collapse',
            icon: GroupOutlinedIcon,
            children: [
                {
                    id: 'util-dlr',
                    title: 'View Dealers',
                    type: 'item',
                    url: `${config.basename}/utils/util-dlr`,
                    breadcrumbs: false
                },
                // {
                //     id: 'util-adddlr',
                //     title: 'Add Dealer',
                //     type: 'item',
                //     url: `${config.basename}/utils/util-adddlr`,
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'util-pyt',
            title: 'Payments',
            type: 'collapse',
            icon: CurrencyRupeeSharpIcon,
            children: [
                {
                    id: 'util-pyt',
                    title: 'View pending Payment',
                    type: 'item',
                    url: `${config.basename}/utils/util-pyt`,
                    breadcrumbs: false
                },
                {
                    id: 'util-mtr',
                    title: 'View all Bank Transfer',
                    type: 'item',
                    url: `${config.basename}/utils/util-mtr`,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'util-distributor',
            title: 'Distributors',
            type: 'collapse',
            icon: GroupOutlinedIcon,
            children: [
                {
                    id: 'util-distributor',
                    title: 'View Distributors',
                    type: 'item',
                    url: `${config.basename}/utils/util-viewdstr`,
                    breadcrumbs: false
                },
                {
                    id: 'util-adddistributor',
                    title: 'Add Distributor',
                    type: 'item',
                    url: `${config.basename}/utils/util-adddstr`,
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'util-globalsetting',
            title: 'Global Settings',
            type: 'collapse',
            icon: SettingsIcon,
            children: [
                {
                    id: 'util-globalsetting',
                    title: 'View Global Settings',
                    type: 'item',
                    url: `${config.basename}/utils/util-globalsetting`,
                    breadcrumbs: false
                },
                {
                    id: 'util-static',
                    title: 'Static Pages',
                    type: 'item',
                    url: `${config.basename}/utils/util-static`,
                    breadcrumbs: false
                },
                {
                    id: 'util-addqa',
                    title: 'Add Queation Answer',
                    type: 'item',
                    url: `${config.basename}/utils/util-addqa`,
                    breadcrumbs: false
                },
                {
                    id: 'util-viewQuaetion',
                    title: 'View All Queation',
                    type: 'item',
                    url: `${config.basename}/utils/util-viewQuaetion`,
                    breadcrumbs: false
                }
            ]
        },
        
        // :
        // ( {
        //     id: 'util-user',
        //     title: 'Users',
        //     type: 'collapse',
        //     icon: GroupOutlinedIcon,
        //     children: [
        //         {
        //             id: 'util-user',
        //             title: 'View Users',
        //             type: 'item',
        //             url: `${config.basename}/utils/util-user`,
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'util-add',
        //             title: 'Add User',
        //             type: 'item',
        //             url: `${config.basename}/utils/util-add`,
        //             breadcrumbs: false
        //         }
        //     ]
        // })}
        // {
        //     id: 'util-shadow',
        //     title: 'Shadow',
        //     type: 'item',
        //     url: '/utils/util-shadow',
        //     icon: ContrastOutlinedIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'icons',
        //     title: 'Icons',
        //     type: 'collapse',
        //     icon: ColorLensOutlinedIcon,
        //     children: [
        //         {
        //             id: 'tabler-icons',
        //             title: 'Tabler Icons',
        //             type: 'item',
        //             url: '/icons/tabler-icons',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'material-icons',
        //             title: 'Material Icons',
        //             type: 'item',
        //             url: '/icons/material-icons',
        //             breadcrumbs: false
        //         }
        //     ]
        // }
    ]
};

export default utilities;
